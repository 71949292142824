import React from "react";
import { Link } from "gatsby";
// @ts-ignore
import AppStore from "../assets/app-store.svg";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Button from "@material-ui/core/Button";
import LogoImageBG from "../components/LogoImageBg";

const AppStoreDownload = () => {
  const [isVideoShowing, setIsVideoShowing] = React.useState(false);
  function showVideo() {
    setIsVideoShowing(true);
  }

  return (
    <main
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        placeContent: "center",
      }}
    >
      <SEO
        title="Download PanoRoom"
        description="Get PanoRoom for your device!"
      />
      <a
        href="https://apps.apple.com/app/apple-store/id1489359406?pt=120014641&ct=other-apps-ads&mt=8"
        style={{
          width: 300,
          height: "100%",
          placeContent: "center",
          display: "flex",
          gap: 10,
          alignItems: "center",
          flexDirection: "column",
          textDecoration: "none",
        }}
      >
        <LogoImageBG
          style={{ height: 300, width: 300, borderRadius: "10px" }}
        />
        <span
          style={{
            fontFamily:
              '"SF Compact Rounded", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            color: `black`,
            fontWeight: 400,
            fontSize: "32pt",
            marginBottom: 20
          }}
        >PanoRoom</span>
        <img src={AppStore} style={{ height: 60, margin: 0 }} />
      </a>
    </main>
  );
};

export default AppStoreDownload;
